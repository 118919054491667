<template>
	<layout-default-new>
		<div>
			<process-report
				:reportHeaders="reportHeaders"
				:report-data="stateInvoiceJournal.documentDefinition"
			/>
		</div>
	</layout-default-new>
</template>

<script>
import storeInvoiceJournal from '@/_srcv2/pages/accounting/page-create-invoice-journal/_shared/storeInvoiceJournal.js'
import { ref, onMounted } from '@vue/composition-api'
export default {
	name: 'InvoiceJournalReport',
	setup() {
		const { stateInvoiceJournal } = storeInvoiceJournal()
		const reportHeaders = ref({})
		onMounted(() => {
			console.log('*******************************')
			console.log(stateInvoiceJournal.documentDefinition)
		})
		return {
			stateInvoiceJournal,
			reportHeaders,
		}
	},
}
</script>

<style scoped></style>
